import {ArticleStatus} from "generated/graphql";

export const ActionRequiredStatuses = [
  ArticleStatus.Submitted, ArticleStatus.Reviewed, ArticleStatus.Refereed, ArticleStatus.Revised
];

export const WaitingStatuses = [
  ArticleStatus.MajorRevision,
  ArticleStatus.MinorRevision, ArticleStatus.RequestSent,
  ArticleStatus.AtReferee, ArticleStatus.AtQuickOpinion
]


export const ActiveStatuses = [...ActionRequiredStatuses, ...WaitingStatuses];