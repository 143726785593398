import {useQuery} from "@apollo/client";
import {DataTable} from "../components/Table/DataTable";
import {FlagButton} from "../components/Input/FlagButton";
import {Link} from "react-router-dom";
import {
    ArticleStatus,
    ConfirmHandlingEditorInput,
    GetAssignedArticlesDocument,
    UserAction
} from "generated/graphql";
import {useAuth} from "../hooks/useAuth";
import {formatDate, formatStatus} from "utilities/formatters";
import {Button} from "antd";
import {useConfirmHandlingEditor} from "hooks/useConfirmHandlingEditor";
import {useState} from "react";
import {DialogButton} from "components/Modal/DialogButton";
import {TextInputDialog} from "routes/Dialogs/TextInputDialog";
import {Column} from "components/Table/Column";
import {ActionRequiredStatuses, ActiveStatuses, WaitingStatuses} from "utilities/statuses";

type ArticleFragment = { __typename?: 'Article', id: number, number: number, title: string, isFlagged: boolean,
    status: ArticleStatus, lastEventDate: any, handlingEditorAccepted: boolean,
    managingEditor?: { __typename?: 'User', id: number, displayName: string } | null,
    handlingEditor?: { __typename?: 'User', id: number, displayName: string } | null,
    lastVersion: { __typename?: 'ArticleVersion', id: string, pageCount: number, dateSubmitted?: any | null },
    authors: Array<{ __typename?: 'Author', displayName: string }>
};

export const Assigned = () => {
    const { data, loading, refetch } = useQuery(GetAssignedArticlesDocument);
    const { can } = useAuth();
    const [isConfirming, setIsConfirming] = useState(false);

    const mutation = useConfirmHandlingEditor();
    const confirm = async (input: ConfirmHandlingEditorInput) => {
        setIsConfirming(true);
        await mutation(input);
        await refetch();
        setIsConfirming(false);
    }

    const isEditor = can(UserAction.ManageArticles);

    const columns: Column<ArticleFragment>[] = [
        {
            key: 'Flagged',
            header: <></>,
            value: a => a.isFlagged ? 'Flagged' : 'Not flagged',
            filter: true,
            render: a => <FlagButton article={a} />
        },
        {
            key: 'Number',
            value: a => a.number,
            render: a => <>{a.number}</>
        },
        {
            key: isEditor ? 'Handling editor' : 'Managing editor',
            value: a => (isEditor ? a.handlingEditor : a.managingEditor)?.displayName,
            filter: true
        },
        {
            key: 'Title',
            value: a => a.title,
            render: a => <Link to={`./article/${a.number}`}>{a.title}</Link>
        },
        {
            key: 'Authors',
            value: a => a.authors.map(p => p.displayName).join(', ')
        },
        {
            key: 'Status',
            value: a => formatStatus(a.status)
        }
    ];

    const activeColumns: Column<ArticleFragment>[] = [
        {
            key: 'Last event',
            value: a => formatDate(a.lastEventDate)
        },
        {
            key: 'Submission date',
            value: a => formatDate(a.lastVersion.dateSubmitted)
        },
        {
            key: '',
            render: a => <>{ !isEditor && !a.handlingEditorAccepted && <>
                <Button type="link" disabled={isConfirming}
                        onClick={() => confirm({ articleId: a.id, accept: true })}>
                    Accept
                </Button>
                <DialogButton disabled={isConfirming}
                              dialog={props => <TextInputDialog
                                title="Decline to handle submission"
                                text="Please could you let us know why; for example, the topic is
    not a good match for your interests, you are too busy right now or you
    feel the paper is highly likely to be rejected (lowest 5%). In the first
    two cases, we would appreciate it if you have any suggestions for
    another editor."
                                {...props} onOk={comment => confirm({ articleId: a.id, accept: false, comment })} />}>
                    Decline
                </DialogButton>
            </> }</>
        }
    ];

    return <>
        { loading && <i>Loading...</i> }
        { data && <div style={{ paddingLeft: '15px', paddingRight: '15px' }}>
            <h2>Active: action required</h2>
            <DataTable source={data.assignedArticles.filter(a => ActionRequiredStatuses.includes(a.status) )}
                       columns={[...columns, ...activeColumns]} />
            <h2>Active: waiting</h2>
            <DataTable source={data.assignedArticles.filter(a => WaitingStatuses.includes(a.status) )}
                       columns={[...columns, ...activeColumns]} />
            <h2>Done</h2>
            <DataTable source={data.assignedArticles.filter(a => !ActiveStatuses.includes(a.status) )} columns={columns} />
        </div> }
    </>
}