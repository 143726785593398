import {ArticleStatus, ArticleWithDatesFragment,
  GetArticlesDocument, GetArticlesWithDatesDocument} from "generated/graphql";
import {CsvExport, DataTable} from "components/Table/DataTable";
import {Column} from "components/Table/Column";
import {useQuery} from "@apollo/client";
import {formatDate, formatStatus} from "utilities/formatters";
import {TexExport} from "utilities/TexExport";
import {useColumns} from "hooks/useColumns";
import {ActiveStatuses} from "utilities/statuses";

interface Props {
  status: ArticleStatus
}

export let articleOrder: number[] = [];
export const setArticleOrder = (order: number[]) => articleOrder = order;

export const ArticleList = ({ status }: Props) => {
  const { data, loading } = useQuery(GetArticlesDocument, { variables: { input: { statuses: [status] } } });

  const columns = useColumns(true);

  setArticleOrder(data?.articles.map(a => a.number) ?? []);

  return <>
    { loading && <i>Loading...</i> }
    { data?.articles && <DataTable columns={columns} source={data.articles}
                                   exports={[CsvExport(status), TexExport(status, data.articles)]} /> }
  </>
}

export const AllArticleList = () => {;
  const defaultColumns = useColumns(true);
  const { data, loading } = useQuery(GetArticlesWithDatesDocument, { variables: { input: { statuses: ActiveStatuses } } });

  let columns = [...(defaultColumns as Column<ArticleWithDatesFragment>[]), {
    key: 'Status',
    value: (a: ArticleWithDatesFragment) => formatStatus(a.status)
  }, {
    key: 'Date submitted',
    value: (a: ArticleWithDatesFragment) => a.firstEventDate,
    render: (a: ArticleWithDatesFragment) => <>{formatDate(a.firstEventDate)}</>
  }, {
    key: 'Last event',
    value: (a: ArticleWithDatesFragment) => a.lastEventDate,
    render: (a: ArticleWithDatesFragment) => <>{formatDate(a.lastEventDate)}</>
  }];

  return <>
    { loading && <i>Loading...</i> }
    { data?.articles && <DataTable columns={columns} source={data.articles}
                                   exports={[CsvExport('ALL')]} /> }
  </>
}